import React, {useEffect} from "react";
import {navigate} from "gatsby";
import {Helmet} from "react-helmet";
import {languages} from "../../languages";

const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
        return "en";
    }

    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";

    console.log(lang);

    switch (lang) {
        case "de":
            return "de";
        default:
            return "en";
    }
}

const IndexPage = ({location}) => {
    useEffect(() => {
        const urlLang = getRedirectLanguage();
        navigate(`/${urlLang}/`, {replace: true});
    }, []);

    return (
        <Helmet>
            {
                languages.map((language) => <link key={language} rel="alternate" hrefLang={language}
                                                  href={`${location.origin}/${language}/`}/>)
            }
        </Helmet>
    );
}

export default IndexPage
